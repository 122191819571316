import React from "react";
const colors = [
  "#B9F3E4",
  "#EA8FEA",
  "#FFAACF",
  "#F6E6C2",
  "#B5F1CC",
  "#E5FDD1",
  "#C9F4AA",
  "#FCC2FC",
  "#F8C4B4",
  "#93BFCF",
  "#BDCDD6",
  "#EEE9DA",
  "#FD8A8A",
  "#F1F7B5",
  "#A8D1D1",
  "#9EA1D4",
  "#9EA1D4",
  "#FFB9B9",
];
export default colors;
